<template>
  <div class="home">

    <h1>Here is Home!</h1>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style>

.home {
  text-align: center;
}

</style>
